<template>
  <div>
    <c-card title="평가대상정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :editable="editable"
            :disabled="true"
            label="공정"
            name="processName"
            v-model="popupParam.processName">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      class="q-mt-sm"
      title="시나리오"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable&&!popupParam.disabled"
      :gridHeightAuto="true"
      :isTitle="true"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :hideBottom="true"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable&&!popupParam.disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="ramKrasAssessScenarioId"
            ibmTaskTypeCd="ITT0000020"
            ibmTaskUnderTypeCd="ITTU000025"
            @imprChange="imprChange"
          >
            <template v-slot:customArea="{ props }">
              <template v-for="(item) in customReadOnlyCols">
                <div class="col-12" :key="item.key">
                  <div class="row">
                    <div class="col-6">
                      <div class="mini-dailog-title">
                        <i class="pe-7s-angle-right-circle"></i>
                        {{item.label}}
                      </div>
                    </div>
                    <div v-if="props.row[item.name]" class="col-12 border-content-dialog">
                      {{props.row[item.name]}}
                    </div>
                    <div v-else class="col-12 border-nocontent-dialog">
                      {{'No Data'}}
                    </div>
                  </div>
                </div>
              </template>
              <q-form ref="editForm">
                <c-card title="시나리오 정보" class="cardClassDetailForm q-mb-sm">
                  <template slot="card-button">
                    <q-btn-group outline >
                      <c-btn 
                        v-if="editable&&!popupParam.disabled" 
                        :isSubmit="isSave"
                        :url="saveUrl"
                        :param="[props.row]"
                        mappingType="PUT"
                        label="저장" 
                        icon="save"
                        @beforeAction="saveScenario(props.row)"
                        @btnCallback="saveScenarioCallback" />
                    </q-btn-group>
                  </template>
                  <template slot="card-detail">
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <c-textarea
                        :disabled="popupParam.disabled"
                        :editable="editable"
                        :rows="2"
                        label="현재안전조치"
                        name="currentSafetyMeasures"
                        v-model="props.row.currentSafetyMeasures">
                      </c-textarea>
                    </div>
                    <div class="col-xs-6 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <c-select
                        :disabled="popupParam.disabled"
                        :editable="editable"
                        :comboItems="riskItems"
                        :isShowLabel="false"
                        anotherLabelText="riskInfo"
                        itemText="risk"
                        itemValue="ramMatrixRiskId"
                        type="edit"
                        name="beforeRamMatrixRiskId"
                        label="개선 전 위험도"
                        v-model="props.row.beforeRamMatrixRiskId"
                      ></c-select>
                    </div>
                    <div class="col-xs-6 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <c-select
                        :disabled="popupParam.disabled"
                        :editable="editable"
                        :comboItems="riskItems"
                        :isShowLabel="false"
                        anotherLabelText="riskInfo"
                        itemText="risk"
                        itemValue="ramMatrixRiskId"
                        type="edit"
                        name="afterRamMatrixRiskId"
                        label="개선 후 위험도"
                        v-model="props.row.afterRamMatrixRiskId"
                      ></c-select>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <c-textarea
                        :disabled="popupParam.disabled"
                        :editable="editable"
                        :rows="2"
                        label="추가 리스크관리 계획"
                        name="improvementMeasures"
                        v-model="props.row.improvementMeasures">
                      </c-textarea>
                    </div>
                  </template>
                </c-card>
              </q-form>
            </template>
          </component>
        </template>
      </template>
    </c-table>
    <div class="popup-bottom-bar">
      <!-- <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn 
            v-if="editable&&!popupParam.disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveScenario"
            @btnCallback="saveScenarioCallback" />
        </q-btn-group>
      </div> -->
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'krasScenario',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',
        processName: '',
        disabled: false,
        ramMatrixId: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'groupMdmSopId' },
          { index: 1, colName: 'riskHazardNameClass' },
        ],
        columns: [
          {
            name: 'jobName',
            field: 'jobName',
            label: '작업',
            align: 'left',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'riskHazardNameClass',
            field: 'riskHazardNameClass',
            label: '분류-유해위험요인',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          // {
          //   name: 'riskOccurrenceCause',
          //   field: 'riskOccurrenceCause',
          //   label: '원인',
          //   align: 'left',
          //   style: 'width:120px',
          //   sortable: false,
          // },
          {
            name: 'before',
            field: 'before',
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                label: '위험도',
                align: 'center',
                style: 'width:60px',
                sortable: false,
              },
            ]
          },
          {
            name: 'after',
            field: 'after',
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterRisk',
                field: 'afterRisk',
                label: '위험도',
                align: 'center',
                style: 'width:60px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '',
            align: 'center',
            style: 'width:25px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      riskItems: [],
      editable: true,
      isSave: false,
      listUrl: '',
      riskListUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    // ReadOnly cols
    customReadOnlyCols() { 
      return [
        {
          key: uid(),
          label: '원인', 
          name: 'riskOccurrenceCause'
        },
        {
          key: uid(),
          label: '결과', 
          name: 'riskOccurrenceResult'
        },
      ]
    },  
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['riskHazardNameClass', 'riskOccurrenceCause'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/mobileTableImpr.vue'}`);
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.kras.scenario.list.url
      this.riskListUrl = selectConfig.ram.matrix.risk.list.url;
      this.saveUrl = transactionConfig.ram.kras.scenario.save.url
      // code setting
      // list setting
      this.getMatrixRisk();
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.popupParam.ramRiskAssessmentPlanId,
        processCd: this.popupParam.processCd,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getMatrixRisk() {
      this.$http.url = this.riskListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramMatrixId: this.popupParam.ramMatrixId,
      }
      this.$http.request((_result) => {
        this.riskItems = _result.data;
      },);
    },
    saveScenario(row) {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$set(row, 'chgUserId', this.$store.getters.user.userId);
              this.$set(row, 'editFlag', 'U');

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveScenarioCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
